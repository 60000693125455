.introduction-container {
    display: flex;
    justify-content: flex-start; 
    padding: 2rem; 
    background-color: var(--background-color); 
}

.introduction-block {
    max-width: 600px; 
    margin-left: 4rem;
}

.introduction-title {
    font-size: 30px; 
    color: var(--text-color);
    font-family: var(--text-font);
    margin-bottom: 10px; 
}

.introduction-name {
    font-size: 75px; 
    color: var(--ascent-color);
    font-family: var(--name-font);
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 50px; 
    opacity: 0; 
    animation: fadeIn 2s ease-out forwards; 
}

@keyframes fadeIn {
    0% {
      opacity: 0; 
    }
    100% {
      opacity: 1; 
    }
}

.introduction-description {
    font-size: 20px; 
    color: var(--text-color);
    font-family: var(--text-font);    
    margin-bottom: 2rem; 
}

@media (max-width: 768px) {
    .introduction-container {
        justify-content: center; 
        align-items: center;     
        padding: 1rem;          
        margin-right: 5rem;

    }
}