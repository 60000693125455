/* Navbar.css */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; 
    display: flex;
    flex-direction: column; 
    background: var(--background-color); 
    height: auto;
}

.top-bar {
    height: 40px; 
    background: linear-gradient(to right, var(--ascent-color), var(--background-color)); 
    width: 100%; 
    margin-bottom: 10px; 
}

.header-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items: center; 
    margin-top: 15px; 

}

.logo {
    font-size: 24px; 
    margin-left: 40px; 
    color: var(--text-color);
    font-family: var(--text-font);
}

.name {
    font-size: 24px; 
    margin-left: 40px; 
    color: var(--background-color);
    font-family: var(--text-font);
    cursor: pointer; 
}

.nav-links {
    list-style-type: none; 
    margin: 0; 
    padding: 0; 
    display: flex; 
    gap: 35px; 
    margin-right: 40px; 
    align-items: center; 
    height: 100%; 
    cursor: pointer; 

}

.link {
    color: var(--text-color); 
    text-decoration: none; 
    font-family: var(--text-font);
    font-size: 18px; 
}

.nav-links .link:hover {
    border-bottom: 2.5px solid var(--ascent-color); 
}

.nav-links .link.active {
    border-bottom: 2px solid var(--ascent-color); 
  }

.resume-link {
    background-color: var(--background-color); 
    color: var(--text-color); 
    padding: 12px 18px; 
    border-radius: 10px; 
    border-color: var(--background-color);
    border-width: 2px;
    border-style: solid;
    cursor: pointer; 
    transition: opacity 0.2s ease, background 0.3s ease, border-image-source 0.3s ease;
    display: inline-block; 
    font-family: var(--text-font);
    font-size: 18px; 
    background: linear-gradient(45deg, var(--ascent-color), var(--background-color)); 
    background-size: 400% 400%; 
    animation: gradient-flow 5s ease infinite;
    filter: brightness(1.2);  

}

.resume-link.hovered {
    animation: gradient-flow 5s ease infinite; 
    opacity: 0.5; 
    cursor: pointer;
}

.resume-link:active {
    opacity: 0.25; 
}

@keyframes gradient-flow {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.mochicat {
    width: 40px;

}

.mochicat-container {
    height: 50px; 
    width: 100%; 
    margin-top: -10px;
}

@media (max-width: 768px) {
    .link {
        color: var(--background-color); 
    }

    .nav-links .link:hover {
        border-bottom: 2.5px solid var(--background-color); 
    }
    
    .nav-links .link.active {
        border-bottom: 2px solid var(--background-color); 
    }

    .resume-link {
        background-color: var(--background-color); 
        color: var(--background-color); 
        padding: 12px 18px; 
        border-radius: 10px; 
        border-color: var(--background-color);
        border-width: 2px;
        border-style: solid;
        cursor: pointer; 
        transition: opacity 0.2s ease, background 0.3s ease, border-image-source 0.3s ease;
        display: inline-block; 
        font-family: var(--text-font);
        font-size: 18px; 
        background: linear-gradient(45deg, var(--background-color), var(--background-color)); 
        background-size: 400% 400%; 
        animation: gradient-flow 5s ease infinite;
        filter: brightness(1.2);  
    
    }
}