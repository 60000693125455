.card {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 18.8%;
    margin: 20px;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    transition: all 0.3s ease; 

}

.card:hover {
    transform: scale(1.05); 
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); 
  }

.image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.content {
    padding: 15px;
}

.title {
    font-size: 1.2em;
    margin: 0 0 8px;
    color: var(--background-color)
}

.description {
    font-size: 0.9em;
    color: var(--background-color);
}

.project-link {
    margin-top: 10px;
    display: inline-block;
    font-size: 0.9em;
    color: var(--ascent-color);
    text-decoration: none;
    font-weight: bold;
}

.project-link:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .card {
        width: 90%; 
        margin: 10px 0; 
    }

    .card-container {
        display: flex;
        flex-direction: column; 
        align-items: center; 
    }
}