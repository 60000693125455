.main-content {
    display: flex; 
    padding-top: 10rem;
    flex-direction: row;  
    justify-content: center;  
    align-items: center;  
    height: 100vh;

}

@media (max-width: 768px) {
    .main-content {
        flex-direction: column; 
        margin-top: 8rem;
        margin-bottom: 5rem;
      

    }
}

