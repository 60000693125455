.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    max-width: 100%; 
    margin-top: 2.2rem;
    margin-bottom: 2.2rem;
    height: 100vh;
}

.contact-title {
    display: flex;
    font-size: 32px; 
    color: var(--text-color);
    font-family: var(--name-font);
    font-weight: 600;
    margin-bottom: 1rem; 
}

.contact-detail-container {
    background-color: var(--ascent-color); 
    border-radius: 15px;
    width: 65%;
    height: 65%;
    box-shadow: inset 0 0 10px rgba(var(--text-color), 0.1), 
                0px 8px 15px rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(var(--text-color), 0.1); 
    transition: box-shadow 0.3s ease;
    padding: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    margin-top: 8cqi;

}

.contact-detail {
    font-size: 18px; 
    color: var(--background-color);
    margin-bottom: 1rem; 
    font-weight: 500;
}

.contact-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;

}

.contact-link-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    margin-top: -4rem;
    margin-left: 10px;
}


.contact-detail-container:hover {
    box-shadow: inset 0 0 15px rgba(var(--text-color), 0.15), 
                0px 12px 20px rgba(0, 0, 0, 0.6);
}

.contact-link {
    color: var(--background-color);
}

.contact-link:hover {
    color: var(--text-color);
}

.contact-link-title {
    color: var(--background-color);
    font-weight: 700;
    font-size: 18px; 

}

.separation-line {
    width: 80px; 
    height: 4px;
    background-color: var(--background-color); 
    margin-top: 15px;
    border-radius: 2px;
}

.contact-icon-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center; 
}

.contact-icon {
    margin-top: 1.5rem;
    width: 40px;
    transition: transform 0.2s ease, opacity 0.2s ease;
    transform-origin: center center; 
}

.contact-icon:hover {
    transform: scale(1.1); 
    opacity: 0.9;
}

@media (max-width: 768px) {
    .contact-container {
        height: auto; 
        padding: 0 1rem; 
        margin-top: -5rem;
        margin-bottom: 5rem;
    }

    .contact-title {
        font-size: 24px; 
        margin-left: 0; 
        margin-right: 0; 
        text-align: center; 
    }

    .contact-detail-container {
        width: 90%; 
        height: auto; 
        flex-direction: column; 
        padding: 2rem; 
        gap: 1rem; 
    }

    .contact-info-container {
        width: 100%; 
        margin-top: 1rem; 
    }

    .contact-link-container {
        width: 100%; 
        margin-top: 0.5rem; 
        margin-left: 0; 
    }

    .contact-icon-container {
        flex-direction: row; 
        gap: 15px; 
        justify-content: center; 
    }

    .contact-icon {
        width: 35px; 
    }

    .separation-line {
        width: 60px; 
    }
}

