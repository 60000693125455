.footer {
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem; 
    color: var(--ascent-color); 
    font-size: 15px; 
}
  
.footer p {
    margin: 0; 
}

@media (max-width: 768px) {
    .footer {
        font-size: 12px; 
        padding: 1rem 0; 
    }
}