.about-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
    gap: 8rem;  
    max-width: 100%; 
    height: 100vh;
    flex-wrap: wrap; /* Allow wrapping of content for mobile */

    
}

.about-block {
    max-width: 550px; 
    text-align: left; 
}

.about-title {
    font-size: 22px; 
    color: var(--ascent-color);
    font-family: var(--name-font);
    font-weight: 600;
    margin-bottom: 20px; 

}

.about-description {
    font-size: 20px;
    color: var(--text-color);
    font-family: var(--text-font);
    margin-bottom: 2rem; 
}

.photo-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    width: 550px;
    height: auto;
    position: relative;
    border-radius: 6px; 
    padding: 7px; 
    background: linear-gradient(45deg, var(--ascent-color), var(--background-color)); 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); 
}

.photo-me {
    width: 100%; 
    height: auto; 
    border-radius: 6px; 
}

.linkedin-button {
    background-color: #0077B5; /* LinkedIn blue */
    color: var(--text-color);
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold; 
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
}
  
.linkedin-button:hover {
    background-color: #005E8C; 
}

.space-between {
    padding-top: 10px;
}

@media (max-width: 768px) and (orientation: portrait) {
    .about-container {
        flex-direction: column;  
        gap: 3rem;  
        height: auto;
        padding: 20px;  

    }

    .about-block {
        max-width: 90%;  
        text-align: center;  
    }

    .about-title {
        margin-top: -2rem;
        font-size: 20px; 
        margin-bottom: 15px;
    }

    .about-description {
        font-size: 18px; 
        
    }

    .photo-container {
        width: 90%;  
        max-width: 500px;  
        margin-bottom: 2rem; 
    }

    .photo-me {
        width: 100%; 
        height: auto;
        border-radius: 6px;
    }

    .linkedin-button {
        width: 100%;  
        padding: 12px;  
        font-size: 18px;  
    }
}

