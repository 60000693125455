.project-container {
    display: flex;
    flex-direction: column;
    max-width: 100%; 
    margin-top: 2rem;
    margin-bottom: 2rem;

}

.project-title {
    display: flex;
    padding: 20px;
    font-size: 32px; 
    color: var(--ascent-color);
    font-family: var(--name-font);
    font-weight: 600;
    margin-bottom: 2.2rem; 
    margin-top: 2.2rem;
    margin-left: 4.8rem;
}

.project-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    margin-top: -35px;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .project-title {
        font-size: 24px; 
        margin-left: 1rem; 
        margin-right: 1rem; 
        text-align: center; 
        padding: 15px; 
        margin-top: -3rem;

    }
}