.experience-container {
    max-width: 100%; 

}


.experience-title {
    display: flex;
    padding: 20px;
    font-size: 32px; 
    color: var(--ascent-color);
    font-family: var(--name-font);
    font-weight: 600;
    margin-bottom: 2.2rem; 
    margin-top: 2.2rem;
    margin-left: 4.8rem;
}

@media (max-width: 768px) {
    .experience-title {
        font-size: 24px; 
        margin-left: 1rem; 
        margin-right: 1rem; 
        text-align: center; 
        padding: 15px;
        margin-top: -3rem;
    }
}