
h3, h5, p {
    font-family: var(--text-font);
}

h5 {
    padding-top: 5px;
}

.title-container {
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    margin-top: -30px;
}
  
.text-container {
    display: flex;
    flex-direction: column; 
}

.timeline-icon {
    width: 88px;  
    height: 100px; 
    object-fit: contain; 
    padding-left: 50px; 
}
  
.vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
        0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 2em!important;
    transition: all 0.3s ease; 
}

.vertical-timeline-element-content:hover {
    box-shadow: 0 0.5em 1em 0 rgba(0, 0, 0, 0.35),  
        0 0.6em 1.5em 0 rgba(0, 0, 0, 0.25);  
    transform: translateY(-5px); 
}


.vertical-timeline-element.work .vertical-timeline-element-content, .vertical-timeline-element.school .vertical-timeline-element-content {
    background-color: #FFFFFF;
}

.vertical-timeline::before {
    background-color: var(--text-color) !important; 
}


#description {
    margin: -0.5em 0 2em 0;
}

.button {
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: var(--text-color);
    font-family: var(--text-font);
}

.button-container {
    margin-bottom: 10px;
}

.workButton {
    background-color: var(--ascent-color);
}

.workButton:hover {
    background-color: #892D49;
}

.schoolButton {
    background-color: var(--ascent-color);
}

.schoolButton:hover {
    background-color: #892D49;
}

@media only screen {
    .vertical-timeline-element-date {
        display: block !important;
        float: none !important;
        color: var(--text-color);
        max-width: 100%; 
    }
}

